<template>
  <div class="page">
    <span style="position: relative;">
      <el-input
        placeholder="请输入搜索关键字"
        v-model="search"
        prefix-icon="el-icon-search"
        clearable
        class="search"
        @blur="keyupSearch($event)"
        @keyup.enter.native="keyupSearch($event)"
      >
      </el-input>
      <!-- <ul class="search_ul" v-if="inputData.length > 0">
        <li
          v-for="(item, index) in inputData"
          :key="index"
          @click="handleKeySearch(item)"
        >
          {{ item.value }}
        </li>
      </ul> -->
    </span>
    <div>
      <ul class="list" v-if="searchList.length > 0">
        <li
          v-for="(item, index) in searchList"
          :key="index"
          class="item"
          @click="handleDetail(item)"
        >
          <div class="cont">
            <div class="cont_name">
              {{ item.name }}
            </div>
          </div>
        </li>
      </ul>
      <div v-else class="no_data">
        <img :src="noData" />
        <span class="text">暂无数据</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import noData from '@/assets/image/nodata.png';

export default {
  components: {},
  computed: {
    ...mapState({
      userInfo: (state) => state.userinfo,
      currentProvince: (state) => state.currentProvince,
      currentYear: (state) => state.currentYear,
      isNew: (state) => state.isNew,
    }),
  },
  data() {
    return {
      search: '',
      activeName: 'first',
      listData: [], //全部院校
      noData: noData,
      rankData: [], //排名
      hot: {
        year: '',
        type: '',
        page: '1',
      },
      compareData: [], //对比
      checkList: [],
      isDel: true,
      search1: {
        keywords: '',
        provinces: [],
        features: [],
        categories: [],
        sort: [],
      },
      checkedData: [],
      inputData: [],
      type: '',
      pcId: 0,
      searchList: [],
    };
  },
  mounted() {
    const { path, query } = this.$router.currentRoute;
    this.search = query.search;
    this.type = query.type;
    this.pcId = query.pcId;
  },
  methods: {
    //search
    async inputSearch() {
      if (this.search.length < 2) {
        return true;
      }
      let apiUrl = `/php/plan.keywords.get?keywords=${this.search}&pcId=${this.pcId}&type=${this.type}`;
      try {
        const res = await this.$axios.get(apiUrl);
        console.log('==res', res);
        if (res.data.ret == 0) {
          this.inputData = res.data.data;
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    async keyupSearch(event) {
      event.target.blur();
      if (this.search.length < 2) {
        return true;
      }
      this.searchList = [];
      let apiUrl = `/php/plan.keywords.get?keywords=${this.search}&pcId=${this.pcId}&type=${this.type}`;
      try {
        const res = await this.$axios.get(apiUrl);
        console.log('==res', res);

        if (res.data.ret == 0) {
          this.searchList = res.data.data;
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    //详情
    handleDetail(item) {
      console.log('====详情', item);
      if (this.type == 'major') {
        this.$router.push({
          path: '/majors/detail',
          query: {
            id: item.id,
            parentId: item.parentCode,
            type: item.eduLevel == 'ben' ? '本科' : '专科',
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  min-height: 100vh;
  background: #fff;
  padding-top: 50px;
}
.search {
  padding: 10px 12px;
  display: flex;
  align-items: center;

  /deep/ .el-input__inner {
    border: none !important;
    height: 40px !important;
    background-color: #f7f8fa;
    border-radius: 2px;
    color: #323233;
    font-size: 14px;
  }
  /deep/ .el-input__prefix {
    left: 15px;
    color: #323233;
  }
  /deep/ .el-input__suffix {
    right: 15px;
  }
}
.search_ul {
  position: absolute;
  background: #fff;
  z-index: 999;
  width: calc(100vw - 28px);
  left: 15px;
  border: 1px solid #ddd;
  border-top: none;
  /* top: 0; */
  margin-top: -10px;
  border-radius: 5px;
  padding: 10px 0;
  li {
    font-size: 14px;
    padding: 6px 20px;
  }
}
.tabs {
  .el-tabs__header {
    border-bottom: 1px solid #f2f2f2;
    height: 44px;
    margin: 0;
    line-height: 44px;
  }
  .el-tabs__nav {
    // display: inline-flex;
    height: 44px;
    // justify-content: space-evenly;
    width: 100%;
  }
  .el-tabs__item {
    font-size: 16px !important;
    font-weight: 600 !important;
    text-align: center;
    line-height: 42px;
    padding: 0 15px !important;
  }
  .el-tabs__item.is-active {
    color: #910000 !important;
  }
  /deep/ .el-tabs__active-bar {
    background-color: #910000;
    border-radius: 5px;
    width: 55px !important;
    margin-left: 22px;
  }
}
.list {
  padding: 10px 15px;
  li.item {
    border-bottom: 1px solid #f2f2f2;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  li.item {
    font-size: 14px;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cont {
      color: #666;
      display: inline-flex;
      align-items: center;
      img {
        width: 20px;
      }
      .cont_num {
        color: #9b9b9b;
        font-size: 16px;
      }
      .cont_name {
        font-size: 16px;
        color: #212121;
        margin-left: 10px;
      }
      .hot {
        color: #666;
      }
    }
  }
}
.del {
  padding: 10px 15px 0;
  text-align: right;
  font-size: 14px;

  .icons {
    font-size: 18px;
    margin-right: 1px;
    vertical-align: text-top;
  }
}
.comp_box {
  padding-bottom: 80px;
  .comp_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #f2f2f2;
    padding: 10px 15px;
    position: fixed;
    bottom: 0;
    width: 100%;
    font-size: 14px;
    background: #fff;
    /deep/ .el-button--primary {
      background: #910000;
      border-color: #910000;
      // opacity: 0.5;
      padding: 10px 30px;
    }
  }
}
.no_data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
  img {
    width: 180px;
  }
  .text {
    font-size: 16px;
    color: #666;
    text-align: center;
    width: 100%;
    font-weight: 600;
    padding-top: 20px;
  }
}
.el-message-box {
  width: 90%;
  .el-button {
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
  }
  .el-button--primary {
    background: #910000;
    border-color: #910000;
    color: #fff;
  }
}
</style>
